import "./Modules.css";
import Modal from "react-modal";
import sprite from "../../icons.svg";
// import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
// import ReactPixel from "react-facebook-pixel";

// eslint-disable-next-line react/prop-types
export const BurgerMenuModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalBurgerMenu"}
      className={"modalContentBurgerMenu"}
      closeTimeoutMS={400}
      onRequestClose={() => onClose()}
      ariaHideApp={false}
    >
      <svg
        className="closeBurgerMenuBtn"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      {children}
    </Modal>
  );
};

// eslint-disable-next-line react/prop-types
export const MoreQuestions = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalMoreQuestion"}
      className={"modalContentMoreQuestion"}
      closeTimeoutMS={400}
      onRequestClose={() => onClose}
      ariaHideApp={false}
    >
      <svg
        className="closeModalQuestions"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleMoreQuestion">
        За додатковими питання зверніться до нас за цими контактами:
      </h2>
      <a href="tel:+380936576711" className="modalContactUsLinks">
        + 38 (093) 657 67 11
      </a>
      <a href="tel:+48667994337" className="modalContactUsLinks">
        +48 667 994 337
      </a>
      <a href="mailto:women@psyconference.org" className="modalContactUsLinks">
        women@psyconference.org
      </a>
      <ul className="wrapperModalSocialLinks">
        <li>
          <a
            href="https://www.instagram.com/women.psyconference/"
            target="_blank"
          >
            <svg className="modalSocialIcons" width={40} height={40}>
              <use xlinkHref={`${sprite}#icon-instagram`}></use>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://t.me/women_psyconference" target="_blank">
            <svg className="modalSocialIcons" width={40} height={40}>
              <use xlinkHref={`${sprite}#icon-telegram`}></use>
            </svg>
          </a>
        </li>
      </ul>
    </Modal>
  );
};

// eslint-disable-next-line react/prop-types
export const ChoiseTariffModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    additionalInfo: "",
    promo: "",
  });

  const promocodesArray = [
    "анна",
    "anna",
    "daria33",
    "mari6",
    "особлива",
    "бажана",
    "bazhana",
    "фейсйога",
    "юлія12",
    "ілона",
    "илона",
    "ilona",
    "dress bar",
    "kseniia",
    "ксенія",
    "кокетство",
  ];

  const [selectedTariffs, setSelectedTariffs] = useState([]);
  const [usersPromo, setUsersPromo] = useState("");
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [oldAmount, setOldAmount] = useState(0);
  const [amount, setAmount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tariffPrices = {
      parmsZero: 29,
      parmsOne: 39,
      parmsTwo: 49,
      parmsThree: 159,
      parmsFour: 999,
      parmsFive: 1699,
    };

    let totalPrice = 0;
    selectedTariffs.forEach((tariffId) => {
      totalPrice += tariffPrices[tariffId];
    });
    setAmount(totalPrice);
  }, [selectedTariffs]);

  useEffect(() => {
    console.log("Updated amount: ", amount);
  }, [amount]);

  const calculatePrice = () => {
    const tariffPrices = {
      parmsZero: 29,
      parmsOne: 39,
      parmsTwo: 49,
      parmsThree: 159,
      parmsFour: 999,
      parmsFive: 1699,
    };

    let totalPrice = 0;
    selectedTariffs.forEach((tariffId) => {
      totalPrice += tariffPrices[tariffId];
    });
    setAmount(totalPrice);
    setOldAmount(0);
    return totalPrice;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleChangePromo = async (e) => {
    const { value } = e.target;

    const usersPromo = value.toLowerCase();
    setUsersPromo(usersPromo);
    calculatePrice();
    setIsPromoValid(false);
    const isPromoValid = promocodesArray.findIndex(
      (promo) => promo === usersPromo.trim()
    );

    if (isPromoValid && usersPromo.trim() === "кокетство") {
      console.log("eqeqw");

      const totalAmount = calculatePrice();
      const newAmount = Math.floor((totalAmount / 100) * 90);
      console.log(newAmount);
      setIsPromoValid(true);
      setOldAmount(totalAmount);
      setAmount(newAmount);
    }
  };

  const handleTariffSelection = (event) => {
    const tariffId = event.target.id;
    setOldAmount(0);
    setIsPromoValid(false);
    setUsersPromo("");
    if (event.target.checked) {
      setSelectedTariffs([...selectedTariffs, tariffId]);
    } else {
      setSelectedTariffs(
        selectedTariffs.filter((tariff) => tariff !== tariffId)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.additionalInfo
    ) {
      alert(
        "Будь ласка, заповніть обов'язкові поля: Ім'я, Прізвище, Email і Додаткова інформація."
      );
      return;
    }

    if (selectedTariffs.length === 0) {
      alert("Будь ласка, оберіть хоча б один тариф.");
      return;
    }

    if (typeof fbq !== "undefined") {
      // eslint-disable-next-line no-undef
      fbq("track", "InitiateCheckout");
    }

    const user = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      additionalInfo: formData.additionalInfo,
      selectedTariffs,
    };

    try {
      const response = await axios.post("https://women.place/create-payment", {
        amount: amount * 100,
        currency: 978,
        redirectUrl: "https://women.place/",
        ...user,
      });

      window.location.href = response.data.pageUrl;
    } catch (error) {
      console.error(
        "Помилка під час створення платежу:",
        error.response ? error.response.data : error.message
      );
      alert(
        `Помилка під час створення платежу: ${
          error.response ? JSON.stringify(error.response.data) : error.message
        }`
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalChoiseTariff"}
      className={"modalContenlChoiseTariff"}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg
        className="closeModalQuestions"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleModalTariffPlan">оберіть тариф/ абонемент:</h2>
      <form className="mainForm">
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsZero" className="labelCheckBoxVisitConference">
            Онлайн участь в одному дні конференції (на вибір)
            <input
              id="parmsZero"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsOne" className="labelCheckBoxVisitConference">
            Онлайн участь у 2-х днях конференції
            <input
              id="parmsOne"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsTwo" className="labelCheckBoxVisitConference">
            Онлайн участь у 2-х днях конференції <br />
            Відеозапис двох виступів з конференції на вибір
            <input
              id="parmsTwo"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsThree" className="labelCheckBoxVisitConference">
            Онлайн участь у 2-х днях конференції <br /> Відеозапис всіх виступів
            конференції
            <input
              id="parmsThree"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsFour" className="labelCheckBoxVisitConference">
            Онлайн участь на 11 конференціях <br /> Відеозаписи всіх виступів з
            11 конференцій
            <input
              id="parmsFour"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsFive" className="labelCheckBoxVisitConference">
            Онлайн участь на 11 конференціях <br />
            Відеозаписи всіх виступів з 11 конференцій <br />
            1 консултація на вибір одного зі спікерів після кожної конференції
            <input
              id="parmsFive"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
      </form>
      <p className="textFormPriceForTariff">
        Вартість{" "}
        <span className="priceSpan">
          {amount}{" "}
          {oldAmount > 0 && (
            <span className="oldPriceSpan">{oldAmount} EUR</span>
          )}
        </span>{" "}
        EUR
      </p>
      <h2 className="titleGetUserData">2. надайте ваші данні:</h2>
      <form className="formForUserData" id="personForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="firstName">
            <input
              className="inputUserDate"
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <span className="spanInputPlaceholder">Імʼя</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="lastName">
            <input
              className="inputUserDate"
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <span className="spanInputPlaceholder">Прізвище</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="email">
            <input
              className="inputUserDate"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <span className="spanInputPlaceholder">Email</span>
          </label>
        </div>
        <div className="form-group">
          <textarea
            id="additionalInfo"
            name="additionalInfo"
            rows="4"
            value={formData.additionalInfo}
            onChange={handleChange}
            required
            placeholder="Додатковий спосіб звʼязку (номер телефону / інстаграм / телеграм"
          ></textarea>
        </div>
        <div className="form-group">
          <label
            className={`labelForUserData ${isPromoValid && "promoLabelValid"}`}
            htmlFor="promo"
          >
            <input
              className="inputUserDate"
              type="text"
              id="promo"
              name="promo"
              value={usersPromo}
              onChange={handleChangePromo}
              placeholder="Промо-код для застосування знижки"
            />
          </label>
        </div>
        <button className="redBtn redBtnFormPaid" type="submit">
          Перейти до оплати
        </button>
      </form>
    </Modal>
  );
};

// eslint-disable-next-line react/prop-types
export const JoinToZoom = ({ isOpen, onClose }) => {
  const zoomURL =
    "https://us06web.zoom.us/j/87080882648?pwd=uvUSMNAMBNKMPFJ4dy0jGPjL2SIoUr.1"; // Ваш URL Zoom

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value.toLowerCase();
    try {
      const res = await axios.patch(
        `https://www.women.place/api/users/${email}`
      );
      console.log("Email scheduled for deletion");

      const deleteAt = res.data.user.deleteAt;

      console.log("deleteAt:", deleteAt);

      if (deleteAt) {
        console.log("proverka");

        // Проверяем просто на наличие значения, null и undefined будут пропущены
        const currentDate = Date.now();
        const deleteAtDate = new Date(deleteAt).getTime();
        console.log(currentDate);
        console.log(deleteAtDate);

        // Проверяем, не истек ли срок доступа
        const isUserHaveAccess = deleteAtDate > currentDate;
        console.log("isUserHaveAccess", isUserHaveAccess);

        if (!isUserHaveAccess) {
          console.log("Время доступа к конференции истекло");
          alert("Час доступу до конференції закінчився!");
          return;
        }
      }

      window.location.href = zoomURL; // Переход в Zoom
    } catch (error) {
      console.log(error);
      alert("Пошта недійсна!"); // Выводим сообщение об ошибке пользователю
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalJoinToZoom"}
      className={"modalContentJoinToZoom"}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg className="closeModalJoinToZoom" onClick={onClose}>
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleModalJoinToZoom">
        Вітаємо! <br />
        <span className="spanModalJoinToZoom">
          надайте ваші данні для авторизації на конференцію
        </span>
      </h2>
      <form className="formJoinToZoom" onSubmit={handleSubmit}>
        <input
          className="inputJoinToZoom"
          type="text"
          placeholder="Email"
          name="email"
          required
        />
        <p className="warningJoinToZoom">
          Важливо написати пошту за якою ви реєструвались, інакше ви не
          потрапите на конференцію
        </p>
        <button className="joinToZoomButton" type="submit">
          Перейти в Zoom
        </button>
      </form>
    </Modal>
  );
};
