import sprite from "../../../icons.svg";
import style from "./FlirtHero.module.css";

export default function FlirtHero() {
    return (
      <section className={style.sectionFlirtHero}>
        <div className="container">
          <article className={style.wrapperBanner}>
            <ul>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextF}`}
                >
                  online
                </p>
              </li>
              <li className={style.wrapperBannerInformation}>
                <svg
                  className={style.iconWomenPlaceBanner}
                  width={205}
                  height={197}
                >
                  <use xlinkHref={`${sprite}#icon-redWomenIMG`}></use>
                </svg>

                <p className={style.heroBannerSecondInfoText}>
                  другий з 11 етапів психоемоційного розвитку
                </p>
                <div className={style.wrapperPresentationTopic}>
                  <h2>Флірт, кокетство, любовна гра</h2>
                </div>
                <p className={style.textAboutWomen}>
                  до жінок, біля жінок, жінками
                </p>
                <button className={style.whiteBtnHero}>
                  Відвідати конференцію
                </button>
              </li>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextS}`}
                >
                  10-11.09
                </p>
              </li>
            </ul>
            <p className={style.womenTextOnBanner1}>жінки</p>
            <p className={style.womenTextOnBanner2}>жінки</p>
            <p className={style.womenTextOnBanner3}>жінки</p>
            <p className={style.womenTextOnBanner4}>жінки</p>
            <p className={style.womenTextOnBanner5}>жінки</p>
            <p className={style.womenTextOnBanner6}>жінки</p>
            <p className={style.womenTextOnBanner7}>жінки</p>
            <p className={style.womenTextOnBanner8}>жінки</p>
            <p className={style.womenTextOnBanner9}>жінки</p>
            <p className={style.womenTextOnBanner10}>жінки</p>
            <p className={style.womenTextOnBanner11}>жінки</p>
            <p className={style.womenTextOnBanner12}>жінки</p>
            <p className={style.womenTextOnBanner13}>жінки</p>
            <p className={style.womenTextOnBanner14}>жінки</p>
          </article>
        </div>
      </section>
    );
}