import { useState, useEffect } from "react";
import { MoreQuestions, ChoiseTariffModal, JoinToZoom } from "../HFM/Modules";
import sprite from "../../icons.svg";
import style from "./Hero.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import About from "../About/About";

export default function Hero() {
  const [prizeModalOpen, setPrizeModalOpen] = useState(false);
  const [tariffModalOpen, setTariffModalOpen] = useState(false);
  const [joinZoomModalOpen, setJoinZoomModalOpen] = useState(false);
  const [setUserDataModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const openModal = (modalName) => {
    console.log(`Opening modal: ${modalName}`);
    if (modalName === "prizeModal") {
      setPrizeModalOpen(true);
    } else if (modalName === "tariffModal") {
      setTariffModalOpen(true);
    } else if (modalName === "userDataModal") {
      setUserDataModalOpen(true);
    } else if (modalName === "joinZoomModal") {
      setJoinZoomModalOpen(true);
    }
  };

  const closeModal = (modalName) => {
    console.log(`Closing modal: ${modalName}`);
    if (modalName === "prizeModal") {
      setPrizeModalOpen(false);
    } else if (modalName === "tariffModal") {
      setTariffModalOpen(false);
    } else if (modalName === "userDataModal") {
      setUserDataModalOpen(false);
    } else if (modalName === "joinZoomModal") {
      setJoinZoomModalOpen(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className={style.heroSection} id="heroSection">
        <div className="container">
          <article
            className={`${style.wrapperBanner} ${loaded ? style.loaded : ""}`}
          >
            <ul>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextF}`}
                >
                  online
                </p>
              </li>
              <li className={style.wrapperBannerInformation}>
                <svg
                  className={style.iconWomenPlaceBanner}
                  width={205}
                  height={197}
                >
                  <use xlinkHref={`${sprite}#icon-redWomenIMG`}></use>
                </svg>

                <p className={style.heroBannerSecondInfoText}>
                  третій з 11 етапів психоемоційного розвитку
                </p>
                <div className={style.wrapperPresentationTopic}>
                  <h2>ЗАБОРОНИ ТА ЇХ ПОРУШЕННЯ</h2>
                </div>
                <p className={style.textAboutWomen}>
                  до жінок, біля жінок, жінками
                </p>
                <button className={style.whiteBtnHero}>
                  Відвідати конференцію
                </button>
              </li>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextS}`}
                >
                  27-28.10
                </p>
              </li>
            </ul>
            <p className={style.womenTextOnBanner1}>жінки</p>
            <p className={style.womenTextOnBanner2}>жінки</p>
            <p className={style.womenTextOnBanner3}>жінки</p>
            <p className={style.womenTextOnBanner4}>жінки</p>
            <p className={style.womenTextOnBanner5}>жінки</p>
            <p className={style.womenTextOnBanner6}>жінки</p>
            <p className={style.womenTextOnBanner7}>жінки</p>
            <p className={style.womenTextOnBanner8}>жінки</p>
            <p className={style.womenTextOnBanner9}>жінки</p>
            <p className={style.womenTextOnBanner10}>жінки</p>
            <p className={style.womenTextOnBanner11}>жінки</p>
            <p className={style.womenTextOnBanner12}>жінки</p>
            <p className={style.womenTextOnBanner13}>жінки</p>
            <p className={style.womenTextOnBanner14}>жінки</p>
          </article>
          <About />
          <div
            className={`${style.wrapperHeroInformation} ${
              loaded ? style.loaded : ""
            }`}
          >
            <article className={style.wrapperNextConferenceInformation}>
              <p className={style.numberOfConference}>
                третій з 11 етапів психоемоційного розвитку
              </p>
              <h2 className={style.titleFirstConference}>
                ЗАБОРОНИ ТА ЇХ ПОРУШЕННЯ
              </h2>
              <h3 className={style.titleFirstConferenceDop}>
                жінок, до жінок, біля жінок
              </h3>
              <p className={style.dateInformation}>
                27-28 жовтня 2024 <span></span> платформа зум
              </p>
              <p className={style.dateInforationMore}>
                з 10:00 до 19:30 за київським часом - перший день <br />
                з 10:00 до 20:15 за київським часом - другий день <br />
                <span>обідня перерва з 14:30 до 15:30</span>
              </p>
              <div className={style.wrapperButtonFirstConference}>
                <button
                  className="redBtn"
                  onClick={() => openModal("tariffModal")}
                >
                  Відвідати конференцію
                </button>
                <button
                  className="transperentBtn"
                  onClick={() => openModal("prizeModal")}
                >
                  Додаткові питання
                </button>
              </div>
            </article>
            <svg className={style.iconRedWomenSVG} width={569} height={546}>
              <use xlinkHref={`${sprite}#icon-redWomenIMG`}></use>
            </svg>
          </div>
          <div className={style.wrapperInfoAboutConference}>
            <ul>
              <li>
                <p className={style.textInfoAboutConferene}>
                  <span>ФЛІРТ</span> <br />
                  Грайливий етап психоемоційного розвитку жінки
                </p>
                <p className={style.textInfoAboutConferene}>
                  Висвітлюючи цю тему на конференції нашими спікерами,
                  <span> ви отримаєте</span> поглиблене розуміння флірту та
                  усвідомлення його важливої ролі у психоемоційному розвитку
                  жінки.
                </p>
              </li>
              <li>
                <p className={style.textInfoAboutConferene}>
                  <span>Ми розкриємо</span> ключові моменти в формуванні флірту
                  на всіх етапах дорослішання, вплив на психоеротичне бажання та
                  мотивацію до подальшого розвитку чи зупинки у розвитку.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>Дізнаєтесь,</span> як, який і коли флірт формується;
                  корисні та зупиняючі наслідки флірту у дорослому житті жінки.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>Розглянемо</span> коли флірт є способом адаптації у
                  травмуючих переживаннях, а коли - здоровою комунікацією, а
                  також чому флірт викликає так багато сексуалізованих
                  переживань
                </p>
              </li>
              <li>
                <p className={style.textInfoAboutConferene}>
                  <span>Це унікальна можливість</span> поглянути на флірт з
                  іншого боку і зрозуміти, як він може бути інструментом для
                  особистісного зростання та встановлення теплих, щирих взаємин.
                  Отримати базові знання розпізнання флірту та тривожних
                  переживань.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>
                    Долучайтеся до нас і ми проведемо вас по всім етапам
                    розвитку ЖІНКИ!
                  </span>
                </p>
              </li>
            </ul>
            <p className={style.aboutFreePlace}>Залишилось 400 місця</p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={40}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            autoHeight={true}
            className={style.mySwiperHeroInfo}
          >
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>ФЛІРТ</span> <br />
                Грайливий етап психоемоційног розвитку жінки
              </p>
              <p className={style.textInfoAboutConferene}>
                Висвітлюючи цю тему на конференції нашими спікерами,
                <span>ви отримаєте</span> поглиблене розуміння флірту та
                усвідомлення його важливої ролі у психоемоційному розвитку
                жінки.
              </p>
              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Ми розкриємо </span>
                ключові моменти в формуванні флірту на всіх етапах дорослішання,
                вплив на психоеротичне бажання та мотивацію до подальшого
                розвитку чи зупинки у розвитку.
              </p>
              <p className={style.textInfoAboutConferene}>
                <span>Дізнаєтесь,</span> як, який і коли флірт формується;
                корисні та зупиняючі наслідки флірту у дорослому житті жінки.
              </p>
              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Розглянемо</span> коли флірт є способом адаптації у
                травмуючих переживаннях, а коли - здоровою комунікацією, а також
                чому флірт викликає так багато сексуалізованих переживань
              </p>
              <p className={style.textInfoAboutConferene}>
                <span>Це унікальна можливість</span> <br /> поглянути на флірт з
                іншого боку і зрозуміти, як він може бути інструментом для
                особистісного зростання та встановлення теплих, щирих взаємин.
                Отримати базові знання розпізнання флірту та тривожних
                переживань.
              </p>
              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>
                  Долучайтеся до нас і ми проведемо вас по всім етапам розвитку
                  ЖІНКИ!
                </span>
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <MoreQuestions
        isOpen={prizeModalOpen}
        onClose={() => closeModal("prizeModal")}
      ></MoreQuestions>
      <ChoiseTariffModal
        isOpen={tariffModalOpen}
        onClose={() => closeModal("tariffModal")}
      ></ChoiseTariffModal>
      <JoinToZoom
        isOpen={joinZoomModalOpen}
        onClose={() => closeModal("joinZoomModal")}
      />
    </>
  );
}
