import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import styles from "./About.module.css";
import { useState } from "react";
import sprite from "../../icons.svg";
import { ChoiseTariffModal } from "../HFM/Modules";

export default function About() {
  const [modalPrizeIsOpen, setPrizeModalOpen] = useState(false);

  return (
    <section className={styles.sectionAbout} id="aboutUsSection">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>
          /Що таке <span className={styles.highlightedTitlePart}>«ЖІНКИ»</span>?
        </h2>

        <div className={`${styles.marqueeContainer} ${styles.onlyOnMobile}`}>
          <div className={styles.marqueeWrapper}>
            <div className={styles.marqueeContent}>
              <p className={styles.marqueeText}>
                Конференція «ЖІНКИ» - <br /> 2-денний онлайн <br /> лекторій про
                кожен <br /> з етапів розвитку жінок.
              </p>
              <p className={styles.marqueeText}>
                Це зіткнення з новим <br />
                досвідом, що призводить <br />
                до переосмислення ситуації <br />
                та свого становища з різних <br />
                сторін: теорія, практика, зустріч.
              </p>
              <p className={styles.marqueeText}>
                Те, що виводить умоглядне <br /> на рівень гранично конкретного
                та реального.
              </p>
              <p className={styles.marqueeText}>
                Конференція «ЖІНКИ» - <br /> 2-денний онлайн <br /> лекторій про
                кожен <br /> з етапів розвитку жінок.
              </p>
              <p className={styles.marqueeText}>
                Це зіткнення з новим <br />
                досвідом, що призводить <br />
                до переосмислення ситуації <br />
                та свого становища з різних <br />
                сторін: теорія, практика, зустріч.
              </p>
              <p className={styles.marqueeText}>
                Те, що виводить умоглядне <br /> на рівень гранично конкретного
                та реального.
              </p>
            </div>
          </div>
        </div>

        <Swiper
          effect="cards"
          grabCursor={true}
          modules={[EffectCards]}
          className={`${styles.swiper} ${styles.onlyOnMobile}`}
          // onSwiper={(swiper) => setSwiperInstance(swiper)}
        >
          <SwiperSlide className={styles.swiperSlide}>
            <h4 className={styles.swiperSlideTitle}>150+ годин</h4>
            <p className={styles.swiperSlideText}>
              глибоких знань та практичних порад для розуміння та дослідження
              себе.
            </p>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <h4 className={styles.swiperSlideTitle}>11 конференцій</h4>
            <p className={styles.swiperSlideText}>
              що розкриють фундаментальні етапи психоемоційного розвитку
            </p>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <h4 className={styles.swiperSlideTitle}>11 виступів спікерів</h4>
            <p className={styles.swiperSlideText}>
              які з різних боків розкриють ключові аспекти жіночої психології
            </p>
          </SwiperSlide>
        </Swiper>
        <div className={`${styles.mainTextContainer} ${styles.onlyOnMobile}`}>
          <p className={styles.mainText}>
            Все, що було ДО - порожні домисли та романтичні фантазії. ПІСЛЯ -
            чіткість, ясність і тверезість. або вперед або назад.
          </p>
          <p className={styles.mainTextHighlighted}>
            Цикл конференцій, які замінить вам кількох експертів психологічного
            розвитку.
          </p>
        </div>
        <div className={`${styles.mainInfoCointaner} ${styles.onlyOnDekstop}`}>
          <div
            className={`${styles.descriptionTextСontainer} ${styles.onlyOnDekstop}`}
          >
            <p className={styles.descriptionText}>
              Конференція «ЖІНКИ» - 2-денний <br /> онлайн лекторій про кожен{" "}
              <br /> з етапів розвитку жінок.
            </p>
            <p className={styles.descriptionText}>
              Це зіткнення з новим <br /> досвідом, що призводить <br /> до
              переосмислення ситуації <br /> та свого становища з різних <br />
              сторін: теорія, практика, зустріч.
            </p>
            <p className={styles.descriptionText}>
              Те, що виводить умоглядне <br /> на рівень гранично <br />
              конкретного та реального.
            </p>
          </div>
          <div className={styles.mainTextContainer}>
            <p className={styles.mainText}>
              Все, що було ДО - порожні <br /> домисли та романтичні <br />{" "}
              фантазії. ПІСЛЯ - чіткість, ясність <br /> і тверезість. або
              вперед або назад.
            </p>
            <p className={styles.mainTextHighlighted}>
              Цикл конференцій, <br /> які замінить вам <br /> кількох експертів{" "}
              <br />
              психологічного <br /> розвитку.
            </p>
          </div>
        </div>
        <div className={`${styles.advantageTable} ${styles.onlyOnDekstop}`}>
          <div className={styles.tableRow}>
            <h4 className={styles.tableRowTitle}>11 конференцій</h4>
            <p className={styles.tableRowText}>
              що розкриють <br /> фундаментальні етапи психоемоційного розвитку
            </p>
          </div>
          <div className={styles.tableRow}>
            <h4 className={styles.tableRowTitle}>11 виступів спікерів</h4>
            <p className={styles.tableRowText}>
              які з різних боків <br /> розкриють ключові аспекти <br /> жіночої
              психології
            </p>
          </div>
          <div className={styles.tableRow}>
            <h4 className={styles.tableRowTitle}>150+ годин</h4>
            <p className={styles.tableRowText}>
              глибоких знань та практичних порад для розуміння та дослідження
              себе.
            </p>
          </div>
        </div>
        <button
          type="button"
          className={styles.buyButton}
          onClick={() => setPrizeModalOpen(true)}
        >
          Придбати абонемент
        </button>
      </div>
      <ChoiseTariffModal
        isOpen={modalPrizeIsOpen}
        onClose={() => setPrizeModalOpen(false)}
      ></ChoiseTariffModal>
      <svg className={styles.spiralDescAnimation} width={600} height={600}>
        <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
      </svg>
    </section>
  );
}
