import styles from "./Header.module.css";
import sprite from "../../icons.svg";
import { BurgerMenuModal } from "./Modules";
import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [transparent, setTransparent] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showTitle, setShowTitle] = useState(true); // Состояние для показа заголовка

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;
    const transparent = currentScrollPos > 100;
    const shrink = currentScrollPos > 50;

    setVisible(visible);
    setTransparent(transparent);
    setShrink(shrink);
    setPrevScrollPos(currentScrollPos);

    // Показ заголовка только если прокрутка выше 100 пикселей
    if (currentScrollPos > 100) {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  }, [prevScrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  return (
    <header
      className={`${styles.header} ${loaded ? styles.loaded : ""} ${
        visible ? styles.visible : styles.hidden
      } ${transparent ? styles.transparent : ""} ${
        shrink ? styles.shrink : ""
      }`}
      id="header"
    >
      <div className={styles.wrapperLogoBurger}>
        <Link to="/">
          <svg className={styles.mainLogoIMG} width={236} height={70}>
            <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
          </svg>
        </Link>

        <svg
          className={styles.headerBurgerMenuBtn}
          width={50}
          height={36}
          onClick={() => setModalPrizeOpen(true)}
        >
          <use xlinkHref={`${sprite}#icon-burger-menu`}></use>
        </svg>
      </div>
      <h1
        className={`${styles.mainTitle} ${
          showTitle ? styles.show : styles.hide
        }`}
      >
        психологічна конференція «ЖІНКИ»<span> (онлайн формат)</span>
      </h1>
      <nav className={styles.headerNavigation}>
        <ul>
          <li className={styles.headerNavigationLink}>
            <a href="#aboutUsSection" className={styles.headerAnchorLink}>
              про нас
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#speakersSection" className={styles.headerAnchorLink}>
              Спікери
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#tariffSection" className={styles.headerAnchorLink}>
              Тарифи
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#upcomingSection" className={styles.headerAnchorLink}>
              Конференції
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#sectionReviews" className={styles.headerAnchorLink}>
              відгуки
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#questionsSection" className={styles.headerAnchorLink}>
              питання
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#donationSection" className={styles.headerAnchorLink}>
              Донат
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#footer" className={styles.headerAnchorLink}>
              Контакти
            </a>
          </li>
        </ul>
      </nav>
      <BurgerMenuModal
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      >
        <ul className="wrapperBurgerNavigation">
          <li className={styles.headerNavigationLink}>
            <a href="#aboutUsSection" onClick={() => setModalPrizeOpen(false)}>
              про нас
            </a>
          </li>
          <li>
            <a href="#speakersSection" onClick={() => setModalPrizeOpen(false)}>
              спікери
            </a>
          </li>
          <li>
            <a href="#tariffSection" onClick={() => setModalPrizeOpen(false)}>
              тарифи
            </a>
          </li>
          <li>
            <a href="#upcomingSection" onClick={() => setModalPrizeOpen(false)}>
              конференції
            </a>
          </li>
          <li>
            <a
              href="#abonementsSection"
              onClick={() => setModalPrizeOpen(false)}
            >
              абонементи
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a href="#sectionReviews" onClick={() => setModalPrizeOpen(false)}>
              відгуки
            </a>
          </li>
          <li className={styles.headerNavigationLink}>
            <a
              href="#questionsSection"
              onClick={() => setModalPrizeOpen(false)}
            >
              питання
            </a>
          </li>
          <li>
            <a href="#donationSection" onClick={() => setModalPrizeOpen(false)}>
              донат
            </a>
          </li>
          <li>
            <a href="#footer" onClick={() => setModalPrizeOpen(false)}>
              контакти
            </a>
          </li>
        </ul>
      </BurgerMenuModal>
    </header>
  );
}
