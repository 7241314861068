// import About from "./About/About";
import Donation from "./Donation/Donation";
import Footer from "./HFM/Footer";
import Header from "./HFM/Header";
import Hero from "./Hero/Hero";
import Questions from "./Questions/Questions";
import Reviews from "./Reviews/Reviews";
import Speakers from "./Speakers/Speakers";
import Tariff from "./Tariff/Tariff";
import Upcoming from "./UpComingConf/Upcoming";

export default function MainPage() {
  return (
    <>
      <Header />
      <Hero />
      <Speakers />
      <Tariff />
      <Upcoming />
      <Reviews />
      <Questions />
      <Donation />
      <Footer />
    </>
  );
}
