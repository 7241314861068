import style from "./Footer.module.css";
import sprite from "../../icons.svg";
import { ChoiseTariffModal } from "./Modules";
import { useState } from "react";

export default function Footer() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);

  return (
    <>
      <footer className={style.footerDesc} id="footer">
        <div className="container">
          <nav className={style.footerNavigation}>
            <ul>
              <li className={style.footerNavigationLink}>
                <a href="#aboutUsSection" className={style.footernavLink}>
                  про нас
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#speakersSection" className={style.footernavLink}>
                  Спікери
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#tariffSection" className={style.footernavLink}>
                  Тарифи
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#upcomingSection" className={style.footernavLink}>
                  Конференції
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#sectionReviews" className={style.footernavLink}>
                  відгуки
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#questionsSection" className={style.footernavLink}>
                  питання
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#donationSection" className={style.footernavLink}>
                  Донат
                </a>
              </li>
            </ul>
          </nav>
          <div className={style.wrapperFooterMainLogo}>
            <svg className={style.footerMainLogoIMG} width={236} height={100}>
              <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
            </svg>
            <ul className={style.wrapperInfoContactList}>
              <li>
                <a
                  href="mailto:women@psyconference.org"
                  className={style.footerContactLinks}
                >
                  women@psyconference.org
                </a>
              </li>
              <li>
                <a
                  href="tel:+380936576711"
                  className={style.footerContactLinks}
                >
                  + 38 (093) 657 67 11
                </a>
              </li>
              <li>
                <a href="tel:+48667994337" className={style.footerContactLinks}>
                  +48 667 994 337
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1_TYyH_dhW66qIaFlRDZe9a01M-8JqNaz/view?usp=sharing"
                  className={style.footerInfoLink}
                >
                  Політика конфіденційності
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1HbwsjGzJwpdm_lp1LWClBbUX8Ci8Q-M1/view?usp=sharing"
                  className={style.footerInfoLink}
                >
                  Умови використання
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1TzXMsE8JAyBvNozd9QM6UVazsIPRkzED/view?usp=sharing"
                  className={style.footerInfoLink}
                >
                  Договір оферти
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1BIitnpEEKD12tyJ40hdB8ldIptkz4rTJ/view?usp=sharing"
                  className={style.footerInfoLink}
                >
                  Згода на обробку персональних даних
                </a>
              </li>
            </ul>
            <div className={style.wrapperFooterSocialIcons}>
              <a
                alt="Instagram"
                aria-label="Перейти на Instagram"
                href="https://www.instagram.com/women.psyconference/"
                target="_blank"
              >
                <svg
                  className={style.footerSocialIconSVG}
                  width={60}
                  height={60}
                >
                  <use xlinkHref={`${sprite}#icon-instagram`}></use>
                </svg>
              </a>
              <a
                alt="Telegram"
                aria-label="Перейти на Telegram"
                href="https://t.me/women_psyconference"
                target="_blank"
              >
                <svg
                  className={style.footerSocialIconSVG}
                  width={56}
                  height={56}
                >
                  <use xlinkHref={`${sprite}#icon-telegram`}></use>
                </svg>
              </a>
            </div>
          </div>
          <div className={style.wrapperPaymentC}>
            <div className={style.wrapperPayment}>
              <svg className={style.footerPaymentSVG} width={60} height={26}>
                <use xlinkHref={`${sprite}#icon-viza`}></use>
              </svg>
              <svg className={style.footerPaymentSVG} width={60} height={26}>
                <use xlinkHref={`${sprite}#icon-mastercard`}></use>
              </svg>
            </div>
            <p className={style.copyright}>&copy; 2024</p>
          </div>
        </div>
      </footer>
      <footer className={style.footerMobile} id="footer">
        <div className="container">
          <svg className={style.footerMainLogoIMG} width={236} height={100}>
            <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
          </svg>
          <nav className={style.footerNavigation}>
            <ul>
              <li className={style.footerNavigationLink}>
                <a href="#speakersSection" className={style.footernavLink}>
                  Спікери
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#tariffSection" className={style.footernavLink}>
                  Тарифи
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#upcomingSection" className={style.footernavLink}>
                  Конференції
                </a>
              </li>
              <li className={style.footerNavigationLink}>
                <a href="#donationSection" className={style.footernavLink}>
                  Донат
                </a>
              </li>
            </ul>
          </nav>
          <ul className={style.wrapperContactUsMobile}>
            <li>
              <a
                href="mailto:women@psyconference.org"
                className={style.footerContactLinks}
              >
                women@psyconference.org
              </a>
            </li>
            <li>
              <a href="tel:+380936576711" className={style.footerContactLinks}>
                + 38 (093) 657 67 11
              </a>
            </li>
            <li>
              <a href="tel:+48667994337" className={style.footerContactLinks}>
                +48 667 994 337
              </a>
            </li>
          </ul>
          <div className={style.wrapperFooterSocialIcons}>
            <a
              alt="Instagram"
              aria-label="Перейти на Instagram"
              href="https://www.instagram.com/women.psyconference/"
              target="_blank"
            >
              <svg className={style.footerSocialIconSVG} width={60} height={60}>
                <use xlinkHref={`${sprite}#icon-instagram`}></use>
              </svg>
            </a>
            <a
              alt="Telegram"
              aria-label="Перейти на Telegram"
              href="https://t.me/women_psyconference"
              target="_blank"
            >
              <svg className={style.footerSocialIconSVG} width={56} height={56}>
                <use xlinkHref={`${sprite}#icon-telegram`}></use>
              </svg>
            </a>
          </div>
          <ul className={style.wrapperInfoContactList}>
            <li>
              <a
                href="https://drive.google.com/file/d/1_TYyH_dhW66qIaFlRDZe9a01M-8JqNaz/view?usp=sharing"
                className={style.footerInfoLink}
              >
                Політика конфіденційності
              </a>
            </li>
            <li>
              <a
                href="https://drive.google.com/file/d/1HbwsjGzJwpdm_lp1LWClBbUX8Ci8Q-M1/view?usp=sharing"
                className={style.footerInfoLink}
              >
                Умови використання
              </a>
            </li>
            <li>
              <a
                href="https://drive.google.com/file/d/1TzXMsE8JAyBvNozd9QM6UVazsIPRkzED/view?usp=sharing"
                className={style.footerInfoLink}
              >
                Договір оферти
              </a>
            </li>
            <li>
              <a
                href="https://drive.google.com/file/d/1BIitnpEEKD12tyJ40hdB8ldIptkz4rTJ/view?usp=sharing"
                className={style.footerInfoLink}
              >
                Згода на обробку персональних даних
              </a>
            </li>
          </ul>
          <div className={style.wrapperPaymentC}>
            <div className={style.wrapperPayment}>
              <svg className={style.footerPaymentSVG} width={60} height={26}>
                <use xlinkHref={`${sprite}#icon-viza`}></use>
              </svg>
              <svg className={style.footerPaymentSVG} width={60} height={26}>
                <use xlinkHref={`${sprite}#icon-mastercard`}></use>
              </svg>
            </div>
            <p className={style.copyright}>&copy; 2024</p>
          </div>
        </div>
      </footer>
      <ChoiseTariffModal
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      >
        <h2 className="titleModalTariffPlan">оберіть тариф/aбонемент:</h2>
        <form action="selectTariff">
          <label htmlFor="">
            <input type="chexbox" />
          </label>
        </form>
      </ChoiseTariffModal>
    </>
  );
}
