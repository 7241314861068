import { useState } from "react";
import Modal from "react-modal";
import sprite from "../../icons.svg";
import "./ModulesAggre.css";
import axios from "axios";

export const ChoiseAggreVideo = ({ isOpen, onClose }) => {
  const [selectedTariffs, setSelectedTariffs] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    additionalInfo: "",
  });

  const tariffPrices = {
    parmsForTwoVideo: 49,
    parmsForAllVideo: 159,
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedTariffs((prevSelected) =>
      checked
        ? [...prevSelected, id]
        : prevSelected.filter((tariff) => tariff !== id)
    );
    if (id === "parmsForTwoVideo" && !checked) {
      setSelectedVideos([]);
    }
  };

  const handleVideoSelection = (e) => {
    const { id, checked } = e.target;

    setSelectedVideos((prevSelected) => {
      if (checked && prevSelected.length < 2) {
        return [...prevSelected, id];
      }
      return prevSelected.filter((videoId) => videoId !== id);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateTotalPrice = () => {
    return selectedTariffs.reduce(
      (total, tariffId) => total + tariffPrices[tariffId],
      0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.additionalInfo
    ) {
      alert(
        "Будь ласка, заповніть обов'язкові поля: Ім'я, Прізвище, Email і Додаткова інформація."
      );
      return;
    }

    if (selectedTariffs.length === 0) {
      alert("Будь ласка, оберіть хоча б один тариф.");
      return;
    }

    if (
      selectedTariffs.includes("parmsForTwoVideo") &&
      selectedVideos.length !== 2
    ) {
      alert("Будь ласка, оберіть дві лекції для вибраного тарифу.");
      return;
    }

    try {
      const agreeMark = true;
      const response = await axios.post("https://women.place/create-payment", {
        amount: calculateTotalPrice() * 100,
        currency: 978,
        redirectUrl: "https://women.place/",
        ...formData,
        selectedTariffs,
        selectedVideos,
        agreeMark,
      });

      // await axios.post("https://women.place/save-user-data", {
      //   ...formData,
      //   selectedTariffs,
      //   selectedVideos,
      // });

      window.location.href = response.data.pageUrl;
    } catch (error) {
      console.error(
        "Помилка під час створення платежу:",
        error.response ? error.response.data : error.message
      );
      alert(
        `Помилка під час створення платежу: ${
          error.response ? JSON.stringify(error.response.data) : error.message
        }`
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={"modalChoiseAggreVideo"}
      className={"modalContentChoiseAggreVideo"}
      closeTimeoutMS={400}
      ariaHideApp={false}
    >
      <svg
        className="closeModalChoiseAggreVideo"
        onClick={onClose}
        width={50}
        height={50}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleChoiseAggreVideo">1. оберіть тариф</h2>
      <form className="formChoiseAggreVideo">
        <div className="wrapperTariffCheck">
          <label
            htmlFor="parmsForTwoVideo"
            className="labelCheckBoxVisitConference"
          >
            Відеозапис двох виступів з конференції на вибір
            <input
              id="parmsForTwoVideo"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleCheckboxChange}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        {selectedTariffs.includes("parmsForTwoVideo") && (
          <div className="wrapperChoisetwoVideoFromAll">
            <h3 className="titleChoisetwoVideoFromAll">оберіть дві лекції:</h3>
            {[
              {
                id: "videoVatrich",
                title: "“Астрологічні показники агресії”",
                speaker: "Ірина Ватрич",
              },
              {
                id: "videoNazarenko",
                title:
                  "“Аутоагресія при складних стосунках з їжею та невдоволенні тілом”",
                speaker: "Тетяна Назаренко",
              },
              {
                id: "videoDiveeva",
                title: "“Соматизація жіночого здоровʼя: де живе агресія”",
                speaker: "Ольга Дівєєва",
              },
              {
                id: "videoMatushevska",
                title: "“Агресія, як провідний інструмент у розвитку жінки”",
                speaker: "Анастасія-Олімпія Матюшевська",
              },
              {
                id: "videoIshkova",
                title: "“Жінка, як держава на карті людства”",
                speaker: "Наталія Ішкова",
              },
              {
                id: "videoPrudnikova",
                title:
                  "“Аудит життя інтерактив з виявлення підсвідомих причин агресивних патернів”",
                speaker: "Вікторія Пруднікова",
              },
              {
                id: "videoMarchek",
                title: "“Бити чи не бити? Що впливає на вибір”",
                speaker: "Світлана Марчек",
              },
              {
                id: "videoAdamova",
                title: "“Руйнівні та пригнічені способи жити своє життя”",
                speaker: "Лєна Адамова",
              },
              {
                id: "videoPavluchok",
                title: "“Жінка лідерка та агресія”",
                speaker: "Наталія Павлючок",
              },
              {
                id: "videoLevchenko",
                title:
                  "“Життя сильної жінки в світі стереотипів про “слабку стать””",
                speaker: "Василіса Левченко",
              },
              {
                id: "videoZimovin",
                title: "“Чоловік в полі жіночої агресії”",
                speaker: "Олексій Зимовін",
              },
              {
                id: "videoBaeva",
                title: "“Жіноча агресія: сили творення і руйнування”",
                speaker: "Олена Баєва",
              },
            ].map((video) => (
              <div key={video.id} className="wrapperTariffCheck">
                <label
                  htmlFor={video.id}
                  className="labelCheckBoxVisitConferenceAggre"
                >
                  <p>
                    {video.title} - <span>{video.speaker}</span>
                  </p>
                  <input
                    id={video.id}
                    type="checkbox"
                    className="custom-checkbox"
                    onChange={handleVideoSelection}
                    disabled={
                      selectedVideos.length >= 2 &&
                      !selectedVideos.includes(video.id)
                    }
                  />
                  <div className="checkbox__checkmark"></div>
                  <div className="checkbox__body"></div>
                </label>
              </div>
            ))}
          </div>
        )}
        <div className="wrapperTariffCheck">
          <label
            htmlFor="parmsForAllVideo"
            className="labelCheckBoxVisitConference"
          >
            Відеозапис всіх виступів конференції
            <input
              id="parmsForAllVideo"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleCheckboxChange}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
      </form>
      <p className="textFormPriceForTariff">
        Вартість: {calculateTotalPrice()} EUR
      </p>
      <h2 className="titleGetUserData">2. надайте ваші данні:</h2>
      <form className="formForUserData" id="personForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="firstName">
            <input
              className="inputUserDate"
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <span className="spanInputPlaceholder">Імʼя</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="lastName">
            <input
              className="inputUserDate"
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <span className="spanInputPlaceholder">Прізвище</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="email">
            <input
              className="inputUserDate"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <span className="spanInputPlaceholder">Email</span>
          </label>
        </div>
        <div className="form-group">
          <label
            className="labelForUserData labelForUserDataBorderNone"
            htmlFor="additionalInfo"
          >
            <textarea
              className="textareaUserData"
              id="additionalInfo"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              required
              placeholder="Додаткова інформація"
            />
          </label>
        </div>
        <div className="form-group">
          <button className="redBtn redBtnForAggreVideo" type="submit">
            Перейти до оплати
          </button>
        </div>
      </form>
    </Modal>
  );
};

export const ModalPlayVideoFromYouTube = ({ isOpen, onClose, videoURL }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={"modalPlayVideoFromYouTube"}
      className={"modalContentPlayVideoFromYouTube"}
      closeTimeoutMS={400}
      ariaHideApp={false}
    >
      <svg
        className="closePlayVideoFromYouTube"
        onClick={onClose}
        width={50}
        height={50}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <iframe
        className="aggreVideoOnModalWindow"
        src={videoURL}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </Modal>
  );
};
